export const DIAGONSTICS_LIST_FAILURE = "DIAGONSTICS_LIST_FAILURE"
export const DIAGONSTICS_LIST_SUCCESS = "DIAGONSTICS_LIST_SUCCESS"

export const SELECTED_DIAGONSTICS_FAILURE = "SELECTED_DIAGONSTICS_FAILURE"
export const SELECTED_DIAGONSTICS_SUCCESS = "SELECTED_DIAGONSTICS_SUCCESS"

export const DASHBOARD_DIAGONSTICS_LIST_FAILURE = "DASHBOARD_DIAGONSTICS_LIST_FAILURE"
export const DASHBOARD_DIAGONSTICS_LIST_SUCCESS = "DASHBOARD_DIAGONSTICS_LIST_SUCCESS"

export const DASHBOARD_SETUP_LIST_FAILURE = "DASHBOARD_SETUP_LIST_FAILURE"
export const DASHBOARD_SETUP_LIST_SUCCESS = "DASHBOARD_SETUP_LIST_SUCCESS"

export const DASHBOARD_DATALOGGER_LIST_FAILURE = "DASHBOARD_DATALOGGER_LIST_FAILURE"
export const DASHBOARD_DATALOGGER_LIST_SUCCESS = "DASHBOARD_DATALOGGER_LIST_SUCCESS"

export const DASHBOARD_CANLOGGER_LIST_FAILURE = "DASHBOARD_CANLOGGER_LIST_FAILURE"
export const DASHBOARD_CANLOGGER_LIST_SUCCESS = "DASHBOARD_CANLOGGER_LIST_SUCCESS"



export const DYNAMIC_MODEL_LIST_FAILURE = "DYNAMIC_MODEL_LIST_FAILURE"
export const DYNAMIC_MODEL_LIST_SUCCESS = "DYNAMIC_MODEL_LIST_SUCCESS"






