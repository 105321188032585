
export const  MACHINE_MODEL_LIST_FAILURE = 'MACHINE_MODEL_LIST_FAILURE';
export const  MACHINE_MODEL_LIST_SUCCESS =  'MACHINE_MODEL_LIST_SUCCESS';


export const  MACHINE_MDS_CODE_LIST_FAILURE = 'MACHINE_MDS_CODE_LIST_FAILURE';
export const  MACHINE_MDS_CODE_LIST_SUCCESS =  'MACHINE_MDS_CODE_LIST_SUCCESS';

export const  MACHINE_MDS_CODE_LIST_ALL_FAILURE = 'MACHINE_MDS_CODE_LIST_ALL_FAILURE';
export const  MACHINE_MDS_CODE_LIST_ALL_SUCCESS =  'MACHINE_MDS_CODE_LIST_ALL_SUCCESS';

export const  MACHINE_MDS_CODE_FAILURE = 'MACHINE_MDS_CODE_FAILURE';
export const  MACHINE_MDS_CODE_SUCCESS =  'MACHINE_MDS_CODE_SUCCESS';

export const  DELETE_MACHINE_MDS_CODE_FAILURE = 'DELETE_MACHINE_MDS_CODE_FAILURE';
export const  DELETE_MACHINE_MDS_CODE_SUCCESS =  'DELETE_MACHINE_MDS_CODE_SUCCESS';

export const  VERIFY_MDS_CODE_USER_FAILURE = 'VERIFY_MDS_CODE_USER_FAILURE';
export const  VERIFY_MDS_CODE_USER_SUCCESS =  'VERIFY_MDS_CODE_USER_SUCCESS';

export const  EDIT_MACHINE_MDS_CODE_FAILURE = 'EDIT_MACHINE_MDS_CODE_FAILURE';
export const  EDIT_MACHINE_MDS_CODE_SUCCESS =  'EDIT_MACHINE_MDS_CODE_SUCCESS';

export const  MACHINE_MODEL_NEW_LIST_FAILURE = 'MACHINE_MODEL_NEW_LIST_FAILURE';
export const  MACHINE_MODEL_NEW_LIST_SUCCESS =  'MACHINE_MODEL_NEW_LIST_SUCCESS';


export const  MACHINE_MODEL_UPDATE_FAILURE = 'MACHINE_MODEL_UPDATE_FAILURE';
export const  MACHINE_MODEL_UPDATE_SUCCESS =  'MACHINE_MODEL_UPDATE_SUCCESS';


