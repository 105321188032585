class CommonService {


    isMobile() {
        let result = false
        if (window.innerWidth <= 780) {
            result = true;
        }
        return result;
    }


    onlyNumberKey(evt) {
        // Only ASCII character in that range allowed
        if (evt.type === "keypress") {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
                return evt.preventDefault();
            }
        }

        if (evt.type === "paste") {
            const clipData = evt.clipboardData;
            const clipText = clipData.getData('text')
            if (clipText.match(/[^0-9 ]/g)) {
                return evt.preventDefault();
            }

        }
    }

    allowAlphabetNumeric(evt) {
        if (evt.type === "keypress") {
            if (evt.key && evt.key.match(/[^a-zA-Z0-9 ]/g)) {
                return evt.preventDefault();
            }
        }
        if (evt.type === "paste") {
            const clipData = evt.clipboardData;
            const clipText = clipData.getData('text')
            if (clipText.match(/[^a-zA-Z0-9 ]/g)) {
                return evt.preventDefault();
            }

        }
    }
    allowAlphabetNumericWithSplChar(evt) {
        if (evt.type === "keypress") {
            if (evt.key && evt.key.match(/[^a-zA-Z0-9_ ]/g)) {
                return evt.preventDefault();
            }
        }
        if (evt.type === "paste") {
            const clipData = evt.clipboardData;
            const clipText = clipData.getData('text')
            if (clipText.match(/[^a-zA-Z0-9_]/g)) {
                return evt.preventDefault();
            }

        }
    }

    
    allowDocumentText(evt) {
        if (evt.type === "keypress") {
            if (evt.key && evt.key.match(/[^-_().a-zA-Z0-9 ]/g)) {
                return evt.preventDefault();
            }
        }
        if (evt.type === "paste") {
            const clipData = evt.clipboardData;
            const clipText = clipData.getData('text')
            if (clipText.match(/[^-_().a-zA-Z0-9 ]/g)) {
                return evt.preventDefault();
            }

        }
    }


    blockEnter(evt) {
        if (evt.type === "keypress") {
            if (evt.charCode === 13) {
                return evt.preventDefault();
            }
        }
    }


    capitalize(s) {
        if (typeof s !== 'string') {
            return '';
        }
        else {
            const myArray = s.split(" ");
            if (myArray.length > 1) {
                let title = " ";
                let i
                for (i = 0; i < myArray.length; i++) {
                    const lastWord = i < myArray.length ? " " : ''
                    title += myArray[i].charAt(0).toUpperCase() + myArray[i].slice(1) + lastWord;
                }
                return title.replace(/^\s+|\s+$/gm, '');
            } else {
                return s.charAt(0).toUpperCase() + s.slice(1)
            }
        }
    }

    batteryVoltage() {
        return 'v'
    }

    timerCount(){
        return 10
    }

    timerCountSocket(){
        return 15000
    }
    onBoardtimerCountSocket(){
        return 25000
    }
    
    timerCountDelay(){
        return 11
    }

}

export default new CommonService();