import React from 'react';
import '../modules/page-not-found/PageNotFoundStyle.scss';
import FooterComponent from '../modules/footer/FooterComponent';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // It will update the state so the next render shows the fallback UI.  
    return { hasError: true };
  }
  redirect() {
    window.location.href = process.env.REACT_APP_HOMEPAGE
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="">
          <div className="page-not-found error-boundary-block">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="273" height="273" viewBox="0 0 273 273">
                <g id="Group_5986" data-name="Group 5986" transform="translate(-515 -150)">
                  <g id="Ellipse_30" data-name="Ellipse 30" transform="translate(515 150)" fill="#fff" stroke="#f9b200" strokeWidth="10">
                    <circle cx="136.5" cy="136.5" r="136.5" stroke="none" />
                    <circle cx="136.5" cy="136.5" r="131.5" fill="none" />
                  </g>
                  <path id="Path_107" data-name="Path 107" d="M18499.4,2427h97.906" transform="translate(-17897.057 -2140.094)" fill="none" stroke="#f9b200" strokeLinecap="round" strokeWidth="10" />
                </g>
              </svg>
            </figure>
            <div className="content-404">
              <h2>Aaaah! Something went wrong</h2>
              <p>Please Contact the Administrator to fix the issue</p>
              <button type='button' onClick={() => this.redirect()} className="login-btn">
                <svg id="home-line" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="Path_92" data-name="Path 92" d="M0,0H24V24H0Z" fill="none" />
                  <path id="Path_93" data-name="Path 93" d="M21,20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V9.49a1,1,0,0,1,.386-.79l8-6.222a1,1,0,0,1,1.228,0l8,6.222A1,1,0,0,1,21,9.49V20Zm-2-1V9.978L12,4.534,5,9.978V19Z" fill="#000" />
                </svg>
              Back to Home
                </button>
            </div>
          </div>
          <FooterComponent />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary