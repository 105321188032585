import  {  SERVICE_LIST_FAILURE,SERVICE_LIST_SUCCESS } from './SericesType';

export const serviceList = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case SERVICE_LIST_SUCCESS:
        case SERVICE_LIST_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

            
