
import  { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS } from './ForgotPasswordType';

export const forgotPassword = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case FORGOT_PASSWORD_FAILURE:
        case FORGOT_PASSWORD_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

            
