
import  { LOGIN_DATA_FAILURE, LOGIN_DATA_SUCCESS } from './LoginPageType';
const initialState = { data: [] , isFetching: false }

export const loginReducer = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case LOGIN_DATA_SUCCESS:
        case LOGIN_DATA_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

            
