import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable'; 
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from "react-redux";
import LayoutComponent from './modules/main-layout/LayoutComponent';
import { store } from './store';
import  './locales/i18n';
import i18next from "i18next";
import io from 'socket.io-client';
window.io = io;  
window.socket = null;
window.i18next = i18next;
// window.io,window.socket  This is used in the dynamic HTML file. dont remove it

i18next.changeLanguage('en');

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <LayoutComponent />
      </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

