import {HELP_TAB_DATA_FAILURE , HELP_TAB_DATA_SUCCESS ,HTML_FILE_FAILURE ,HTML_FILE_SUCCESS, MACHINE_FAILURE_FAILURE,MACHINE_FAILURE_SUCCESS, CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILURE , SELECTED_CATEGORY_SUCCESS ,SELECTED_CATEGORY_FAILURE ,FAULT_CODE_SUCCESS ,FAULT_CODE_FAILURE} from './HelpType'

export const HelpCategoryList = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case CATEGORY_LIST_SUCCESS:
        case CATEGORY_LIST_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const HelpSelectedCategory = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case SELECTED_CATEGORY_SUCCESS:
        case SELECTED_CATEGORY_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}

export const HelpFaultCode = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case FAULT_CODE_SUCCESS:
        case FAULT_CODE_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}



export const machineFailureProcedure = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_FAILURE_FAILURE:
        case MACHINE_FAILURE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const htmlFileCode = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case HTML_FILE_FAILURE:
        case HTML_FILE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const helpTab = (  state = { isFetching: false, data: [] } , { type ,payload } = {}) => {
    switch (type) {
        case HELP_TAB_DATA_FAILURE:
        case HELP_TAB_DATA_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}