import {DELETE_MODULE_NAME_FAILURE,DELETE_MODULE_NAME_SUCCESS,ADD_MODULE_NAME_FAILURE,ADD_MODULE_NAME_SUCCESS,EDIT_MODULE_NAME_FAILURE,EDIT_MODULE_NAME_SUCCESS,CREATE_DOCUMENT_FAILURE,CREATE_DOCUMENT_SUCCESS,FETCH_DOCUMENT_LIST_SUCCESS,FETCH_DOCUMENT_LIST_FAILURE,UPDATE_DOCUMENT_FAILURE,UPDATE_DOCUMENT_SUCCESS,DELETE_DOCUMENT_FAILURE,DELETE_DOCUMENT_SUCCESS,EDIT_MACHINE_SUCCESS,EDIT_MACHINE_FAILURE ,ADD_MACHINE_SUCCESS,ADD_MACHINE_FAILURE,DELETE_MACHINE_SUCCESS,DELETE_MACHINE_FAILURE, FETCH_LOG_LIST_FAILURE,FETCH_LOG_LIST_SUCCESS, DEBOARD_VIN_SUCCESS,DEBOARD_VIN_FAILURE,
    CREATE_CATEGORY_FAILURE, CREATE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILURE, DELETE_CATEGORY_SUCCESS, EDIT_CATEGORY_FAILURE, EDIT_CATEGORY_SUCCESS, DELETE_HTML_FAILURE, DELETE_HTML_SUCCESS, EDIT_HTML_FAILURE, EDIT_HTML_SUCCESS,
    UPLOAD_HTML_FAILURE, UPLOAD_HTML_SUCCESS, FETCH_CATEGORY_LIST_FAILURE, FETCH_CATEGORY_LIST_SUCCESS, FETCH_HTML_LIST_FAILURE, FETCH_HTML_LIST_SUCCESS, FETCH_USER_PROFILE_FAILURE, FETCH_USER_PROFILE_SUCCESS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_SUCCESS, GET_FILTER_ROLE_LIST_FAILURE, GET_FILTER_ROLE_LIST_SUCCESS, DELETE_ROLE_FAILURE, DELETE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE, UPDATE_ROLE_SUCCESS, CREATE_ROLE_FAILURE, CREATE_ROLE_SUCCESS
    , FETCH_ROLE_LIST_FAILURE, FETCH_ROLE_LIST_SUCCESS, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, ON_BOARD_SUCCESS, ON_BOARD_FAILURE, FETCH_USER_LIST_FAILURE, FETCH_USER_LIST_SUCCESS, CREATE_USER_FAILURE, CREATE_USER_SUCCESS,ADD_MODEL_NAME_FAILURE,ADD_MODEL_NAME_SUCCESS, ADD_VARIANT_NAME_SUCCESS, ADD_VARIANT_NAME_FAILURE, EDIT_MODEL_NAME_FAILURE, EDIT_MODEL_NAME_SUCCESS, EDIT_VARIANT_NAME_SUCCESS, EDIT_VARIANT_NAME_FAILURE, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE, UPDATE_RELEASE_SUCCESS, UPDATE_RELEASE_FAILURE, GET_RELEASE_SUCCESS, GET_RELEASE_FAILURE, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE
} from './UserType'
import CommonApi from '../../utility/CommonApi';


/**
 * fetchCategoryList() returns category list data from the API
 *
 */
export const fetchCategoryList = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + `/user/category/all`, data ,(result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200) {
                dispatch(fetchCategorySuccess(result.data))
            }
            if (result.data.status === 400) {
                dispatch(fetchCategorySuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchCategoryFail(error));
    })
}




/**
 * fetchHtmlList() returns HTML list data from the API
 *
 */
export const fetchHtmlList = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + `/user/html/all`, data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(fetchHtmlListSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchHtmlListFail(error));
    })
}








/**
 * fetchOnBoardList() returns onboard list data from the API
 *
 */
export const fetchOnBoardList = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/machine', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(fetchOnBoardListSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchOnBoardListFail(error));
    })
}


/**
 * fetchUserList() returns  list of user data from the API
 *
 */
export const fetchUserList = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management/filter', data, (result) => {
        if (result && result.status) {
            if (result.status === 200 || result.status === 204) {
                dispatch(fetchUserListSuccess(result))
            }
        }
    }, (error) => {
        dispatch(fetchUserListFail(error));
    })
}




/**
 * fetchRoleList() returns  list of role data from the API
 *
 */
export const fetchRoleList = () => async (dispatch) => {
    await CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/roles/filter', (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200) {
                dispatch(fetchRoleListSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchRoleListFail(error));
    })
}



/**
 * fetchFilterRoleList() returns  list of role data  except dealer from the API
 *
 */
export const fetchFilterRoleList = () => async (dispatch) => {
    await CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/roles/meta', (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200) {
                dispatch(fetchRoleListSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(fetchRoleListFail(error));
    })
}






/**
 * uploadHtml()   use to upload the html file*
 */
export const uploadHtml = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/html', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(uploadHtmlSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(uploadHtmlFail(error));
    }, 'media')
}




/**
 * updateHtml()   use to update the html file*
 */
export const updateHtml = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/html', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(editHtmlSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(editHtmlFail(error));
    }, 'media')
}




/**
 * deboardVin()  use to deboard VIN *
 */
 export const deboardVin = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/machine', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deboardVinSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(deboardVinFail(error));
    })
}




/**
 * deleteMachine()  use to remove  VIN *
 */
 export const deleteMachine = (data) => async (dispatch) => {
     
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/machine/newmachine/drop' , data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deleteMachineSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(deleteMachineFail(error));
    })
}



/**
 * editMachine()  use to edit  VIN *
 */
 export const editMachine = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/machine/new', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(editMachineSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(editMachineFail(error));
    })
}





/**
 * deleteHtml()   use to delete the html file*
 */
export const deleteHtml = (data) => async (dispatch) => {
    await CommonApi.callDeleteBodyAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/html', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deleteHtmlSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(deleteHtmlFail(error));
    })
}




/**
 * deleteCategory()   use to delete the Category*
 */
export const deleteCategory = (data) => async (dispatch) => {
    await CommonApi.callDeleteBodyAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/category', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deleteCategorySuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(deleteCategoryFail(error));
    })
}



/**
 * updateCategory()   use to update the Category*
 */
export const updateCategory = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/category', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400 || result.data.status === 403) {
                dispatch(editCategorySuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(editCategoryFail(error));
    })
}



/**
 * createCategory()   use to add new  Category*
 */
export const createCategory = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/category', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(createCategorySuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(createCategoryFail(error));
    })
}


/**
 * createDocument()   use to add new  document*
 */
 export const createDocument = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/media', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(createDocumentSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(createDocumentFail(error));
    })
}


/**
 * updateDocument()   use to update the  document*
 */
 export const updateDocument = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/media', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(updateDocumentSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(updateDocumentFail(error));
    })
}


/**
 * deleteDocument()   use to delte the  document*
 */
 export const deleteDocument = (data) => async (dispatch) => {
    await CommonApi.callDeleteBodyAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/media', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(deleteDocumentSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(deleteDocumentFail(error));
    })
}


/**
 * fetchDocument()   use to fetch all  document*
 */
 export const fetchDocument = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/media/all', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(fetchDocumentSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchDocumentFail(error));
    })
}



/**
 * createMachine()   use to add new  Machine*
 */
 export const createMachine = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/machine/new', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400  || result.data.status === 403) {
                dispatch(createMachineSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(createMachineFail(error));
    })
}



/**
 * createUser() returns  user created status from the API
 *
 */
export const createUser = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(createUserSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(uploadHtmlFail(error));
    })
}



/**
 * deleteUser()  remove the  user  from the database *
 */
export const deleteUser = (data) => async (dispatch) => {
    await CommonApi.callDeleteAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deleteUserSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(deleteUserFail(error));
    })
}



/**
 * updateUser()  use to update  the  user  information in the  database *
 */
export const updateUser = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateUserSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateUserFail(error));
    })
}





/**
 * createRole() insert role *
 */
export const createRole = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/roles', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(createRoleSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(createRoleFail(error));
    })
}



/**
 * deleteRole()  remove the  Role  from the database *
 */
export const deleteRole = (data) => async (dispatch) => {
    await CommonApi.callDeleteAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/roles', data, (result) => {
        if (result && result.data && result.data.status) {

            if (result.data.status === 200 || result.data.status === 400 || result.data.status === 500) {
                dispatch(deleteRoleSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(deleteRoleFail(error));
    })
}



/**
 * updateRole()  use to update  the  role  information in the  database *
 */
export const updateRole = (id,data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + `/user/roles?id=${id}`, data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateRoleSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateRoleFail(error));
    })
}


/**
 * changePassword()  use to update  the  user  password information in the  database *
 */
export const changePassword = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management/changepassword', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(chagePasswordSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(chagePasswordFail(error));
    })
}



/**
 * getUserProfileInfo() returns  list of user data  API
 *
 */
export const getUserProfileInfo = (id) => async (dispatch) => {
    await CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/management?id=' + id, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 403) {
                dispatch(fetchUserProfileSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(fetchUserProfileFail(error));
    })
}



/**
 * fetchLogList() returns  Log List
 *
 */
 export const fetchLogList = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/log' ,data,(result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(fetchLogSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(fetchLogFail(error));
    })
}


/**
 * createVariantName() insert Variant name *
 */
export const createVariantName = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modelvariant/add', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(addVariantNameSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(addVariantNameFail(error));
    })
}


/**
 * updateModuleName() update Module name *
 */
export const editVariantName = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modelvariant/update', data, (result) => {
        if (result && result.data && result.data.status) {

            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateVariantNameSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateVariantNameFail(error));
    })
}

/**
 * createModelName() insert Model name *
 */
export const createModelName = (data) => async (dispatch) => {

    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modelvariant/add', data, (result) => {

        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(addModelNameSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(addModelNameFail(error));
    })
}


/**
 * updateModuleName() update Module name *
 */
export const editModelName = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modelvariant/update', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateModelNameSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateModelNameFail(error));
    })
}



/**
 * createModuleName() insert Module name *
 */
 export const createModuleName = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modules', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(addModuleNameSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(addModuleNameFail(error));
    })
}


/**
 * updateModuleName() update Module name *
 */
 export const updateModuleName = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modules', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateModuleNameSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateModuleNameFail(error));
    })
}



/**
 * deleteModuleName()   use to delete Module name*
 */
 export const deleteModuleName = (data) => async (dispatch) => {
    await CommonApi.callDeleteBodyAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/modules', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(deleteModuleNameSuccess(result.data))
            }
        }
    }, (error) => {
        dispatch(deleteModuleNameFail(error));
    })
}


/**
 * getSettings() update Module name *
 */
export const getSettings = () => async (dispatch) => {
    await CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/settings', (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(getSettingsSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(getSettingsFail(error));
    })
}


/**
 * updateSettings() update Module name *
 */
export const updateSettings = (data) => async (dispatch) => {
    await CommonApi.callPutAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/settings', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateSettingsSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateSettingsFail(error));
    })
}

/**
 * getRelease() update Module name *
 */
export const getRelease = () => async (dispatch) => {
    await CommonApi.callGetAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/release-notes', (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(getReleaseSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(getReleaseFail(error));
    })
}


/**
 * updateRelease() update Module name *
 */
export const updateRelease = (data) => async (dispatch) => {
    await CommonApi.callPostAPI(process.env.REACT_APP_BACKEND_BASE_URL + '/user/release-notes', data, (result) => {
        if (result && result.data && result.data.status) {
            if (result.data.status === 200 || result.data.status === 400) {
                dispatch(updateReleaseSuccess(result.data))
            }

        }
    }, (error) => {
        dispatch(updateReleaseFail(error));
    })
}



/**
 * getSettingsSuccess
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const getSettingsSuccess = (data) => ({
    type: GET_SETTINGS_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
 * getSettingsFail
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const getSettingsFail = (data) => ({
    type: GET_SETTINGS_FAILURE,
    payload: data,
    isFetching: true,
});


/**
 * updateSettingsSuccess
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateSettingsSuccess = (data) => ({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
 * updateSettingsFail
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateSettingsFail = (data) => ({
    type: UPDATE_SETTINGS_FAILURE,
    payload: data,
    isFetching: true,
});

/**
 * getReleaseSuccess
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const getReleaseSuccess = (data) => ({
    type: GET_RELEASE_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
 * getSettingsFail
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const getReleaseFail = (data) => ({
    type: GET_RELEASE_FAILURE,
    payload: data,
    isFetching: true,
});

/**
 * updateReleaseSuccess
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateReleaseSuccess = (data) => ({
    type: UPDATE_RELEASE_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
 * updateSettingsFail
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateReleaseFail = (data) => ({
    type: UPDATE_RELEASE_FAILURE,
    payload: data,
    isFetching: true,
});



/**
 * Set fetcg category data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchCategoryFail = (data) => ({
    type: FETCH_CATEGORY_LIST_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set fetcg category  data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchCategorySuccess = (data) => ({
    type: FETCH_CATEGORY_LIST_SUCCESS,
    payload: data,
    isFetching: false,
});





/**
* Set  HTML List  data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchHtmlListSuccess = (data) => ({
    type: FETCH_HTML_LIST_SUCCESS,
    payload: data,
    isFetching: false,
});


/**
 * Set HTML Listdata Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchHtmlListFail = (data) => ({
    type: FETCH_HTML_LIST_FAILURE,
    payload: data,
    isFetching: true,
});





/**
* Set  User profile data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchUserProfileSuccess = (data) => ({
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: data,
    isFetching: false,
});


/**
 * Set User profile data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchUserProfileFail = (data) => ({
    type: FETCH_USER_PROFILE_FAILURE,
    payload: data,
    isFetching: true,
});





/**
* Set  Change password  data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const chagePasswordSuccess = (data) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
    isFetching: false,
});


/**
 * Set  Change password  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const chagePasswordFail = (data) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: data,
    isFetching: true,
});










/**
 * Set update Role  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateRoleFail = (data) => ({
    type: UPDATE_ROLE_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set update Role data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateRoleSuccess = (data) => ({
    type: UPDATE_ROLE_SUCCESS,
    payload: data,
    isFetching: false,
})








/**
 * Set delete Role  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const deleteRoleFail = (data) => ({
    type: DELETE_ROLE_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set delete Role data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteRoleSuccess = (data) => ({
    type: DELETE_ROLE_SUCCESS,
    payload: data,
    isFetching: false,
})










/**
 * Set create Role  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const createRoleFail = (data) => ({
    type: CREATE_ROLE_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set reate Role data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const createRoleSuccess = (data) => ({
    type: CREATE_ROLE_SUCCESS,
    payload: data,
    isFetching: false,
})





/**
 * Set create Machine  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const createMachineFail = (data) => ({
    type: ADD_MACHINE_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set reate Machine data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const createMachineSuccess = (data) => ({
    type: ADD_MACHINE_SUCCESS,
    payload: data,
    isFetching: false,
})



/**
 * Set Role list data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchRoleListFail = (data) => ({
    type: FETCH_ROLE_LIST_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set Role list data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchRoleListSuccess = (data) => ({
    type: FETCH_ROLE_LIST_SUCCESS,
    payload: data,
    isFetching: false,
})





/**
 * Set update user data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateUserFail = (data) => ({
    type: UPDATE_USER_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set update user data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateUserSuccess = (data) => ({
    type: UPDATE_USER_SUCCESS,
    payload: data,
    isFetching: false,
});




/**
 * Set OnBoard list data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchOnBoardListFail = (data) => ({
    type: ON_BOARD_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set OnBoard list data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchOnBoardListSuccess = (data) => ({
    type: ON_BOARD_SUCCESS,
    payload: data,
    isFetching: false,
});





/**
 * Set create User data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const createUserFail = (data) => ({
    type: CREATE_USER_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set create User data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const createUserSuccess = (data) => ({
    type: CREATE_USER_SUCCESS,
    payload: data,
    isFetching: false,
});




/**
 * Set User list  data Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchUserListFail = (data) => ({
    type: FETCH_USER_LIST_FAILURE,
    payload: data,
    isFetching: true,
});



/**
* Set  User list data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchUserListSuccess = (data) => ({
    type: FETCH_USER_LIST_SUCCESS,
    payload: data,
    isFetching: false,
});





/**
 * Set  delete User  status Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const deleteUserFail = (data) => ({
    type: DELETE_USER_FAILURE,
    payload: data,
    isFetching: true,
});




/**
* Set  Filter Role List data Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchFilterRoleListSuccess = (data) => ({
    type: GET_FILTER_ROLE_LIST_SUCCESS,
    payload: data,
    isFetching: false,
});





/**
 * Set  Filter Role List  status Failure
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const fetchFilterRoleListFail = (data) => ({
    type: GET_FILTER_ROLE_LIST_FAILURE,
    payload: data,
    isFetching: true,
});





/**
* Set  delete User  status Success
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteUserSuccess = (data) => ({
    type: DELETE_USER_SUCCESS,
    payload: data,
    isFetching: false,
});








/**
 * Set  upload HTML status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const uploadHtmlSuccess = (data) => ({
    type: UPLOAD_HTML_SUCCESS,
    payload: data,
    isFetching: true,
});





/**
* Set  upload HTML status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const uploadHtmlFail = (data) => ({
    type: UPLOAD_HTML_FAILURE,
    payload: data,
    isFetching: false,
});






/**
 * Set  delete HTML status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const deleteHtmlSuccess = (data) => ({
    type: DELETE_HTML_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  delete HTML status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteHtmlFail = (data) => ({
    type: DELETE_HTML_FAILURE,
    payload: data,
    isFetching: false,
});






/**
 * Set  edit HTML status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const editHtmlSuccess = (data) => ({
    type: EDIT_HTML_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  edit HTML status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const editHtmlFail = (data) => ({
    type: EDIT_HTML_FAILURE,
    payload: data,
    isFetching: false,
});





/**
 * Set  deboard Vinstatus success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const deboardVinSuccess = (data) => ({
    type: DEBOARD_VIN_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  deboard Vin status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deboardVinFail = (data) => ({
    type: DEBOARD_VIN_FAILURE,
    payload: data,
    isFetching: false,
});




/**
 * Set  deleteMachine  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const deleteMachineSuccess = (data) => ({
    type: DELETE_MACHINE_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  deleteMachine status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteMachineFail = (data) => ({
    type: DELETE_MACHINE_FAILURE,
    payload: data,
    isFetching: false,
});




/**
 * Set  editMachine  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const editMachineSuccess = (data) => ({
    type: EDIT_MACHINE_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  editMachine status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const editMachineFail = (data) => ({
    type: EDIT_MACHINE_FAILURE,
    payload: data,
    isFetching: false,
});











/**
 * Set  edit category status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const editCategorySuccess = (data) => ({
    type: EDIT_CATEGORY_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set   edit category status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const editCategoryFail = (data) => ({
    type: EDIT_CATEGORY_FAILURE,
    payload: data,
    isFetching: false,
});






/**
 * Set  delete category status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const deleteCategorySuccess = (data) => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  delete category status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteCategoryFail = (data) => ({
    type: DELETE_CATEGORY_FAILURE,
    payload: data,
    isFetching: false,
});







/**
 * Set  create category status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const createCategorySuccess = (data) => ({
    type: CREATE_CATEGORY_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  create category status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const createCategoryFail = (data) => ({
    type: CREATE_CATEGORY_FAILURE,
    payload: data,
    isFetching: false,
});






/**
 * Set  fetch Log  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const fetchLogSuccess = (data) => ({
    type: FETCH_LOG_LIST_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  fetch Log status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchLogFail = (data) => ({
    type: FETCH_LOG_LIST_FAILURE,
    payload: data,
    isFetching: false,
});



/**
 * Set  fetch Document  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const fetchDocumentSuccess = (data) => ({
    type: FETCH_DOCUMENT_LIST_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  fetch Document status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const fetchDocumentFail = (data) => ({
    type: FETCH_DOCUMENT_LIST_FAILURE,
    payload: data,
    isFetching: false,
});



/**
 * Set  update Document  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const updateDocumentSuccess = (data) => ({
    type: UPDATE_DOCUMENT_SUCCESS,
    payload: data,
    isFetching: true,
});



/**
* Set  create Document status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const createDocumentFail = (data) => ({
    type: CREATE_DOCUMENT_FAILURE,
    payload: data,
    isFetching: false,
});



/**
 * Set  create Document  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const createDocumentSuccess = (data) => ({
    type: CREATE_DOCUMENT_SUCCESS,
    payload: data,
    isFetching: true,
});





/**
* Set  update Document status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateDocumentFail = (data) => ({
    type: UPDATE_DOCUMENT_FAILURE,
    payload: data,
    isFetching: false,
});


/**
 * Set  Delete Document  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const deleteDocumentSuccess = (data) => ({
    type: DELETE_DOCUMENT_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  Delete Document status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteDocumentFail = (data) => ({
    type: DELETE_DOCUMENT_FAILURE,
    payload: data,
    isFetching: false,
});


/**
 * Set  ADD Variant name  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const addVariantNameSuccess = (data) => ({
    type: ADD_VARIANT_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
* Set  ADD Variant status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const addVariantNameFail = (data) => ({
    type: ADD_VARIANT_NAME_FAILURE,
    payload: data,
    isFetching: false,
});

/**
 * Set  Update module name success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateVariantNameSuccess = (data) => ({
    type: EDIT_VARIANT_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  update module status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateVariantNameFail = (data) => ({
    type: EDIT_VARIANT_NAME_FAILURE,
    payload: data,
    isFetching: false,
});


/**
 * Set  ADD Model name  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const addModelNameSuccess = (data) => ({
    type: ADD_MODEL_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});

/**
* Set  ADD Model status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const addModelNameFail = (data) => ({
    type: ADD_MODEL_NAME_FAILURE,
    payload: data,
    isFetching: false,
});


/**
 * Set  Update module name success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
export const updateModelNameSuccess = (data) => ({
    type: EDIT_MODEL_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  update module status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateModelNameFail = (data) => ({
    type: EDIT_MODEL_NAME_FAILURE,
    payload: data,
    isFetching: false,
});



/**
 * Set  ADD module name  status success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const addModuleNameSuccess = (data) => ({
    type: ADD_MODULE_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  ADD module status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const addModuleNameFail = (data) => ({
    type: ADD_MODULE_NAME_FAILURE,
    payload: data,
    isFetching: false,
});




/**
 * Set  Update module name success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const updateModuleNameSuccess = (data) => ({
    type: EDIT_MODULE_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  update module status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const updateModuleNameFail = (data) => ({
    type: EDIT_MODULE_NAME_FAILURE,
    payload: data,
    isFetching: false,
});







/**
 * Set  Delete module name success
 * @param  {Object} data - sets empty string
 * @return {Object}
 */
 export const deleteModuleNameSuccess = (data) => ({
    type: DELETE_MODULE_NAME_SUCCESS,
    payload: data,
    isFetching: true,
});




/**
* Set  delete module status Failure
* @param  {Object} data - sets empty string
* @return {Object}
*/
export const deleteModuleNameFail = (data) => ({
    type: DELETE_MODULE_NAME_FAILURE,
    payload: data,
    isFetching: false,
});

