import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import PublicRouter from './PublicRouter'
import PrivateRouter from './PrivateRouter'
import Loader from '../assets/images/loading.svg';


const LoginPageComponent = lazy(() => import('../modules/login-related/login-page/LoginPageComponent'));
const ForgotPasswordComponent = lazy(() => import('../modules/login-related/forgot-password/ForgotPasswordComponent'));
const ResetPasswordComponent = lazy(() => import('../modules/login-related/reset-password/ResetPasswordComponent'));
const MachineModelComponent = lazy(() => import('../modules/machine-model-List/MachineModelComponent'));
const HomePageComponent = lazy(() => import('../modules/home-page/HomePageComponent'));
const DetailDashboardComponent = lazy(() => import('../modules/home-page/detail-dashboard/DetailDashboardComponent'));
const HelpComponent = lazy(() => import('../modules/help/HelpComponent'));
const ServicesComponent = lazy(() => import('../modules/services/ServicesComponent'));
const SelectedCategoryComponent = lazy(() => import('../modules/help/selected-category/SelectedCategoryComponent'));
const ServiceProcedure = lazy(() => import('../modules/help/service-procedure/ServiceProcedureComponent'));
const ManageUserComponent = lazy(() => import('../modules/user-related/manage-user/ManageUserComponent'));
const ChangePasswordComponent = lazy(() => import('../modules/user-related/change-password/ChangePasswordComponent'));
const ChangePasswordComponentLogin = lazy(() => import('../modules/user-related/change-password/ChangePasswordComponentLogin'));
const MyProfileComponent = lazy(() => import('../modules/user-related/my-profile/MyProfileComponent'));
const MyProfileComponentLogin = lazy(() => import('../modules/user-related/my-profile/MyProfileComponentLogin'));
const OnBoardComponent = lazy(() => import('../modules/user-related/manage-onboarding/OnBoardComponent'));
const FaultCodeComponent = lazy(() => import('../modules/faultcode/FaultCodeComponent'));
const CategoryListingComponent = lazy(() => import('../modules/dynamic-modules/category-listing/CategoryListingComponent'));
const ViewSelectedCategoryComponent = lazy(() => import('../modules/dynamic-modules/view-selected-category/ViewSelectedCategoryComponent'));
const ProcedureDetailComponent = lazy(() => import('../modules/help/procedure-detail/ProcedureDetailComponent'));
const RolesAndPermissionsComponent = lazy(() => import('../modules/user-related/manage-role/RolesAndPermissionsComponent'));
const ManageHtmlComponent = lazy(() => import('../modules/user-related/manage-html/ManageHtmlComponent'));
const ManageCategoryComponent = lazy(() => import('../modules/user-related/manage-category/ManageCategoryComponent'));
const LogComponent = lazy(() => import('../modules/user-related/manage-log/LogComponent'));
const ViewHtmlComponent = lazy(() => import('../modules/help/view-html/ViewHtmlComponent'));
const ModelVariantComponent = lazy(() => import('../modules/user-related/manage-settings/ModelVariantComponent'));
const DocumentComponent = lazy(() => import('../modules/user-related/manage-document/DocumentComponent'));
const ReleaseNotesComponent = lazy(() => import('../modules/user-related/release-notes/ReleaseNotesComponent'));
const ReleaseNotesComponentLogin = lazy(() => import('../modules/user-related/release-notes/ReleaseNotesComponentLogin'));

class Router extends React.Component {

    constructor(props){
        super(props)
        this.pathurl = ""
    }

    componentDidUpdate(){
        this.pathurl = this.props.location
      }
      

      render() {
    return (

        
        <Suspense fallback={<div className="loader-content">
            <img src={Loader} loading="lazy" alt="loader" />
        </div>}>
            
            <Switch >
                <PublicRouter exact={true} path="/" component={LoginPageComponent} />
                <PublicRouter exact={true} path="/login" component={LoginPageComponent} />
                <PrivateRouter exact={true} path="/model-list" component={MachineModelComponent} />
                <PrivateRouter exact={true} path="/home" component={HomePageComponent} />
                <PublicRouter exact={true}  path="/forgot-password" component={ForgotPasswordComponent} />
                <PublicRouter exact={true}  path="/reset-password/:id" component={ResetPasswordComponent} />
                <PrivateRouter exact={true} path="/help" component={HelpComponent} />
                <PrivateRouter exact={true} path="/services" component={ServicesComponent} />
                <PrivateRouter exact={true} path="/help/category/:id" component={SelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/help/fault-detail/:categoryId/:faultcodeId" component={ServiceProcedure} />
                <PrivateRouter exact={true} path="/faultcode/fault-detail/:faultcodeId" component={ServiceProcedure} />
                <PrivateRouter exact={true} path="/faultcode/fault/procedure/:Id" component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/fault/procedure/:categoryId/:Id" component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/failure/procedure/:categoryId/:Id" component={ProcedureDetailComponent} />
                <PrivateRouter exact={true} path="/help/html/:categoryId/:id" component={ViewHtmlComponent} />
                <PrivateRouter exact={true} path="/manage-user" routeGuard={true} component={ManageUserComponent} />
                <PrivateRouter exact={true} path="/manage-category" routeGuard={true} component={ManageCategoryComponent} />
                <PrivateRouter exact={true} path="/manage-html" routeGuard={true} component={ManageHtmlComponent} />
                <PrivateRouter exact={true} path="/faultcode" routeGuard={true} component={FaultCodeComponent} />
                <PrivateRouter exact={true} path="/model/:moduleId/:modelName" component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/model/:moduleId/:modelName/:categoryId" routeGuard={true} component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/setup" component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/setup/:categoryId" component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/datalogger" component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/datalogger/:categoryId" component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/canlogger" component={CategoryListingComponent} />
                <PrivateRouter exact={true} path="/canlogger/:categoryId" component={ViewSelectedCategoryComponent} />
                <PrivateRouter exact={true} path="/change-password" routeGuard={true}  component={ChangePasswordComponent} />
                <PrivateRouter exact={true} path="/change-password-login" routeGuard={true}  component={ChangePasswordComponentLogin} />
                <PrivateRouter exact={true} path="/my-profile" routeGuard={true}  component={MyProfileComponent} />
                <PrivateRouter exact={true} path="/my-profile-login" routeGuard={true}  component={MyProfileComponentLogin} />
                <PrivateRouter exact={true} path="/manage-role" routeGuard={true} component={RolesAndPermissionsComponent} />
                <PrivateRouter exact={true} path="/manage-onboard" routeGuard={true} component={OnBoardComponent} />
                <PrivateRouter exact={true} path="/manage-modelvariant" routeGuard={true} component={ModelVariantComponent} />
                <PrivateRouter exact={true} path="/manage-document" routeGuard={true}   component={DocumentComponent} />
                <PrivateRouter exact={true} path="/log"  routeGuard={true}  component={LogComponent} />
                <PrivateRouter exact={true} path="/log-dashboard"  routeGuard={true} component={DetailDashboardComponent} />
                <PrivateRouter exact={true} path="/release-notes" routeGuard={true}  component={ReleaseNotesComponent} />
                <PrivateRouter exact={true} path="/release-notes-login" routeGuard={true}  component={ReleaseNotesComponentLogin} />
                <PublicRouter   path="/"  exact={false} />
            </Switch>
        </Suspense>
    )
      }
}

export default Router