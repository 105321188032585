import Authorization from '../utility/Authorization';
import { Route, Redirect ,withRouter} from "react-router-dom";

let params='';
const PrivateRouter = ({ component: Component, dispatch, exact, routeGuard = false, path, ...rest }) => {
    return (<Route {...rest} render={(props) => {
        params = props.match.params
        let isVin = false
        const vinNumber = Authorization.getVinNumber();
        if (vinNumber) {
            isVin = true;
        }
        if (Authorization.isLoggedIn() && path === '/model-list') { return <Component path={path} exact={exact}></Component> }
        else if (Authorization.isLoggedIn() && !Authorization.isVariantSelect() && !isVin && !props.location.pathname==='/model-list') { return <Redirect to='/model-list'></Redirect> }
        else if (Authorization.isLoggedIn() && Authorization.isVariantSelect() && !isVin) { return <Redirect to='/model-list'></Redirect> }
        else if (!Authorization.isLoggedIn() && Authorization.isVariantSelect() && isVin) { return <Redirect to={process.env.REACT_APP_HOMEPAGE}></Redirect> }
        else if (!Authorization.isLoggedIn() && !Authorization.isVariantSelect() && !isVin) { return <Redirect to={process.env.REACT_APP_HOMEPAGE}></Redirect> }
        // else if (Authorization.isLoggedIn() && Authorization.isVariantSelect() && isVin) {
        if (routeGuard) {
                const pathAccess = checkRouteGaurd(path);
                if (pathAccess) {
                    return <Component path={path} exact={exact}></Component>
                } else {
                    return <Redirect to='/'></Redirect>
                }

            } else {
                return <Component path={path} exact={exact}></Component>
            }



        // }
    }} />)
}

function checkPageAccess(val){
   return Authorization.getSelectedModuleAction(val, "view");
}
function checkFaultCodeAccess(val){
    return Authorization.getSelectedFaultCodeFrom(val)
}

const checkRouteGaurd = (path) => {
    let adminAccess = false;
    const userType = Authorization.getAuthType();
    if (userType && userType.type && userType.type === "Admin") {
        adminAccess = true
    }
    if (path === "/manage-user") {
        return checkPageAccess("manageUsers");
    } else if (path === "/manage-role") {
        return checkPageAccess("manageRoles");
    } else if (path === "/manage-onboard") {
        return checkPageAccess("onboardingDevice");
    } else if (path === "/manage-html") {
        return checkPageAccess("manageHtml");
    } else if (path === "/manage-category") {
        return checkPageAccess("manageCategory");
    } else if (path === "/log") {
        return checkPageAccess("manageLog");
    } else if (path === "/manage-modelvariant") {
        return checkPageAccess("manageModelVariant");
    } else if (path === "/manage-document") {
        return checkPageAccess("manageDocument");
    }else if (path === "/log-dashboard") {
        return checkPageAccess("manageDashboard");
    }else if (path === "/faultcode") {
        return checkFaultCodeAccess('faultcode');
    }else if (path === "/model/:moduleId/:modelName/:categoryId") {
      if(params.modelName === 'Faultcode'){
        return checkFaultCodeAccess('model')
      }else{
        return true;
      }
    }
    else if (path === "/my-profile") {
        return adminAccess;
    } else if (path === "/my-profile-login") {
        return adminAccess;
    } else if (path === "/change-password") {
        return adminAccess;
    }else if (path === "/change-password-login") {
        return adminAccess;
    } else if (path === "/log-dashboard") {
        return adminAccess;
    } else if(path === "/release-notes"){
        return adminAccess;
    } else if(path === "/release-notes-login"){
        return adminAccess;
    }

    else {
        return null;
    }

}


export default withRouter(PrivateRouter)