export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';
export const SELECTED_CATEGORY_SUCCESS = 'SELECTED_CATEGORY_SUCCESS';
export const SELECTED_CATEGORY_FAILURE = 'SELECTED_CATEGORY_FAILURE';
export const FAULT_CODE_SUCCESS = 'FAULT_CODE_SUCCESS';
export const FAULT_CODE_FAILURE = 'FAULT_CODE_FAILURE';
export const MACHINE_FAILURE_FAILURE = 'MACHINE_FAILURE_FAILURE';
export const MACHINE_FAILURE_SUCCESS = 'MACHINE_FAILURE_SUCCESS';
export const HTML_FILE_FAILURE = 'HTML_FILE_FAILURE';
export const HTML_FILE_SUCCESS = 'HTML_FILE_SUCCESS';

export const HELP_TAB_DATA_FAILURE = 'HELP_TAB_DATA_FAILURE';
export const HELP_TAB_DATA_SUCCESS = 'HELP_TAB_DATA_SUCCESS';
