
import  {MODEL_VARIANT_UPDATE_SUCCESS,MODEL_VARIANT_UPDATE_FAILURE,  FETCH_VIN_LIST_SUCCESS , FETCH_VIN_LIST_FAILURE, MODEL_VARIANT_LIST_FAILURE , MODEL_VARIANT_LIST_SUCCESS } from './PopupType';


export const PopupReducer  = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MODEL_VARIANT_LIST_FAILURE:
        case MODEL_VARIANT_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const vinList  = (  state = { isFetching: false, data: [ ] } , { type ,payload }= {}) => {
    switch (type) {
        case FETCH_VIN_LIST_SUCCESS:
        case FETCH_VIN_LIST_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const updateVariant  = (  state = { isFetching: false, data: [ ] } , { type ,payload }= {}) => {
    switch (type) {
        case MODEL_VARIANT_UPDATE_SUCCESS:
        case MODEL_VARIANT_UPDATE_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}