
import axios from 'axios';
import Authorization from './Authorization';
import i18next from 'i18next';
import CustomToast from './custom-toaster/CustomToast';

class CommonAPi {

    constructor() {
        this.headers = {};
    }


    
    
    getHeaders(type='') {
        let accessToken  = ''
        accessToken  = Authorization.getAccessToken();
        if(type ===""){
            this.headers = { 'Content-Type': 'application/json'};
        }
        if(type ==="media"){
            this.headers = { "Content-Type": "multipart/form-data"};

        }
        if(accessToken){
            this.headers['Authorization'] = accessToken 
        }
        return this.headers;
    }

    callGetAPI = async (url, successCallback, errorCallback) => {
        await axios.get( url, {
            headers: this.getHeaders()
        }).then((result) => {  if(result.data.status === 401) { this.sessionExpired(); } else if(result.data.status === 500) { CustomToast.error(result.data.message) }  else { return successCallback(result)}}).catch((err) => {
            CustomToast.error(i18next.t('TOAST.API_ERROR'));
            console.log('API ' + url, err);
            errorCallback({status:500});
        });
    }


    callPostAPI = async (url, parameter , successCallback, errorCallback ,headerType='') => {
        await axios.post(url, parameter ,{  
            headers: this.getHeaders(headerType)
        }).then((result) =>  {  if(result.data.status === 401) { this.sessionExpired();  } else if(result.data.status === 500) { CustomToast.error(result.data.message)} else { return successCallback(result)}}).catch((err) => {
            console.log('API ' + url, err);
            CustomToast.error(i18next.t('TOAST.API_ERROR'));
            errorCallback({status:500})
        });
    }


    callDeleteAPI = async (url, parameter , successCallback, errorCallback) => {
        await axios.delete(url + parameter ,{  
            headers: this.getHeaders()
        }).then((result) =>  {  if(result.data.status === 401) { this.sessionExpired(); } else if(result.data.status === 500) { CustomToast.error(result.data.message)} else { return successCallback(result)}}).catch((err) => {
            console.log('API ' + url, err);
            CustomToast.error(i18next.t('TOAST.API_ERROR'));
            errorCallback(err)
        });
    }




    callDeleteBodyAPI = async (url, parameter , successCallback, errorCallback,headerType='') => {
        await axios.delete( url, {  
            data: parameter ,
            headers: this.getHeaders(headerType)} ).then((result) =>  {  if(result.data.status === 401) { this.sessionExpired();    } else if(result.data.status === 500) { CustomToast.error(result.data.message)} else { return successCallback(result)}}).catch((err) => {
            console.log('API ' + url, err);
            CustomToast.error(i18next.t('TOAST.API_ERROR'));
            errorCallback(err)
        });
    }




    callPutAPI = async (url, parameter , successCallback, errorCallback) => {
        await axios.put( url , parameter ,{  
            headers: this.getHeaders()
        }).then((result) =>  {  if(result.data.status === 401) { this.sessionExpired();    } else if(result.data.status === 500) { CustomToast.error(result.data.message)} else { return successCallback(result)}}).catch((err) => {
            console.log('API ' + url, err);
            CustomToast.error(i18next.t('TOAST.API_ERROR'));
            errorCallback(err)
        });
    }



    sessionExpired(){
        CustomToast.error(i18next.t('TOAST.SESSION_EXPIRED')); 
        setTimeout(()=>{
            Authorization.logout();
        },1500)
      
    }


}

export default new CommonAPi();