
import  { RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS } from './ResetPasswordType';
 const initialState = { isFetching: false, data: [] }

export const resetPassword = (  state = initialState , { type ,payload } = {} ) => {
    switch (type) {
        case RESET_PASSWORD_FAILURE:
        case RESET_PASSWORD_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

            
