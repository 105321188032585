import React from 'react';
import { Link ,withRouter} from 'react-router-dom';
import i18next from "i18next";
import LoginUser from '../../assets/images/profile-user.png';
import DownArrow from '../../assets/images/arrow_down.png';
import Authorization from '../../utility/Authorization';
import CustomToast from '../../utility/custom-toaster/CustomToast';

class ProfileMenuComponent extends React.Component {

    constructor(props){
        super(props)
        this.unlisten = ''
        this.state ={allowChangePass:true,allowChangePassMsg:'', allowUser:true,allowUserMsg:'', profileMenu:false ,userName:'',userType:'' ,pathName:this.props.location.pathname}
    }

    componentDidMount(){
        let userType = ''
        let name = ''
        const authUser = Authorization.getAuthUser();
        userType = Authorization.getAuthType();
        if (userType && userType.type) {
            userType = userType.type;
        }
        if (authUser && authUser.name) {
            name = authUser.name
        }
        const viewAccess = Authorization.getSelectedModuleAction('manageProfileAndPassword', "view");
        const updateAccess = Authorization.getSelectedModuleAction('manageProfileAndPassword', "update");
        if(!viewAccess){
            this.setState({ allowUser:false,allowUserMsg:'Access Denied' });
        }
        if(!updateAccess){
            this.setState({ allowChangePass:false,allowChangePassMsg:'Access Denied' });
        }

        this.setState({userType: userType,userName: name})
          this.unlisten =  this.props.history.listen((location, action) => {
            this.setState({pathName :location.pathname })
            })


        if(document.querySelector("#profile-block")){
            const concernedElement = document.querySelector("#profile-block");

            window.addEventListener("mousedown", (e) => {
                if (concernedElement.contains(e.target)) {
                    this.state.profileMenu ? this.setState({ profileMenu: false }) : this.setState({ profileMenu: true })
                } else {
                    if(this.state.profileMenu){
                        if(document.querySelector("#login-activity")){
                            const concernedList = document.querySelector("#login-activity");
                            if (!concernedList.contains(e.target)) {
                                this.setState({ profileMenu: false });
                            }
                        }

                    }
                }
              });
        }
    }


    /*Use to logout the user from the application*/
    logOut() {
        Authorization.logout('noredirect');
        this.props.history.push('/');
        CustomToast.success("Logout Successfully");
    }

    componentWillUnmount(){
       this.unlisten();
    }

    navigateProfile(){
        if(this.state.allowUser){
            this.props.history.push('/my-profile')
        }else{
            if(this.state.allowUserMsg){
                CustomToast.error(this.state.allowUserMsg)
            }
        }   
          
    }
    navigateChangePass(){
        if(this.state.allowChangePass){
            this.props.history.push('/change-password')
        }else{
            if(this.state.allowChangePassMsg){
                CustomToast.error(this.state.allowChangePassMsg)
            }
        }   
    }

    navigateReleaseNotes(){
        this.props.history.push('/release-notes')
    }


    render(){
        return(
            <div>
            <div className="header-profile-details" id='profile-block'>
                <figure>
                    <img src={LoginUser} className="user-login" alt="profile" loading="lazy" />
                </figure>
                <div className="header-profile">
                    <span className='username'>{this.state.userName}</span>
                    <img src={DownArrow} alt="Down arrow" loading="lazy" />
                </div>
            </div>
            {this.state.profileMenu &&
            <ul id="login-activity">
                {this.state.userType === "Admin" && this.state.pathName !==  '/model-list' &&
                    <li className="stroke-profile" onClick={()=>this.navigateProfile()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.028" height="20.881" viewBox="0 0 17.028 19.881">
                            <path id="Icon_awesome-user" data-name="Icon awesome-user" d="M7.664,7.759a4.38,4.38,0,1,0-4.38-4.38A4.379,4.379,0,0,0,7.664,7.759ZM10.73,9.517h-.571a5.956,5.956,0,0,1-4.989,0H4.6a4.6,4.6,0,0,0-4.6,4.6v1.423a1.643,1.643,0,0,0,1.642,1.642H13.686a1.643,1.643,0,0,0,1.642-1.642V14.115A4.6,4.6,0,0,0,10.73,9.517Z" transform="translate(0.85 1.85)" fill="none" stroke="#333" strokeWidth="1.7" />
                        </svg>
                        <span>{i18next.t('HEADER.MY_PROFILE')} </span>
                    </li>
                }
                {this.state.userType === "Admin" && this.state.pathName !==  '/model-list' &&
                    <li className="fill-profile" onClick={()=>this.navigateChangePass()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.775" height="12.805" viewBox="0 0 9.775 12.805">
                            <path id="Path_121" data-name="Path 121" d="M24.5,10.31V6.275a.792.792,0,0,0-.791-.791H18.1a.792.792,0,0,0-.791.791V10.31a1.321,1.321,0,0,0-1.292,1.32v5.338a1.321,1.321,0,0,0,1.321,1.321h7.132a1.321,1.321,0,0,0,1.321-1.321V11.63A1.321,1.321,0,0,0,24.5,10.31ZM23.206,6.778v3.53H18.6V6.778Zm-2.3,8.964a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,20.905,15.743Z" transform="translate(-16.018 -5.484)" fill="#333" />
                        </svg>
                        <span>{i18next.t('HEADER.CHANGE_PASSWORD')} </span>
                    </li>
                }
                { this.state.pathName !==  '/model-list' &&
                <li className="fill-profile">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.72" height="13.705" viewBox="0 0 13.72 13.705">
                        <path id="Icon_ionic-ios-help-circle" data-name="Icon ionic-ios-help-circle" d="M10.235,3.375a6.852,6.852,0,1,0,6.86,6.852A6.855,6.855,0,0,0,10.235,3.375ZM10.093,13.39a.679.679,0,1,1,.709-.679A.688.688,0,0,1,10.093,13.39ZM11.419,10.2c-.574.333-.768.577-.768,1v.26H9.506l-.01-.283a1.371,1.371,0,0,1,.778-1.45c.557-.333.792-.544.792-.952a.8.8,0,0,0-.887-.708.834.834,0,0,0-.884.81H8.124a1.884,1.884,0,0,1,2.134-1.812c1.237,0,2.088.685,2.088,1.67A1.612,1.612,0,0,1,11.419,10.2Z" transform="translate(-3.375 -3.375)" fill="#333" />
                    </svg>
                    <Link to="#">{i18next.t('GENERAL.HELP')}</Link>
                </li>
                }
                { this.state.pathName !==  '/model-list' &&
                <li className="fill-profile" onClick={()=>this.navigateReleaseNotes()}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="mdi-note-text" width="13.72" height="13.705" viewBox="0 0 24 24"><path d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" /></svg>
                    <Link to="#">{i18next.t('GENERAL.RELEASE_NOTES')}</Link>
                </li>
                }
                <li className="logout stroke-profile" onClick={() => this.logOut()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.628" height="13.628" viewBox="0 0 13.628 13.628">
                        <g id="Group_6007" data-name="Group 6007" transform="translate(1 1)">
                            <g id="Icon_feather-log-in" data-name="Icon feather-log-in">
                                <path id="Path_1" data-name="Path 1" d="M22.5,4.5h2.584a1.292,1.292,0,0,1,1.292,1.292v9.044a1.292,1.292,0,0,1-1.292,1.292H22.5" transform="translate(-14.748 -4.5)" fill="none" stroke="#f9b200" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path id="Path_2" data-name="Path 2" d="M15,16.96l3.23-3.23L15,10.5" transform="translate(-10.478 -7.916)" fill="none" stroke="#f9b200" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path id="Path_3" data-name="Path 3" d="M12.252,18H4.5" transform="translate(-4.5 -12.186)" fill="none" stroke="#f9b200" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </g>
                        </g>
                    </svg>
                    <span>{i18next.t('GENERAL.LOGOUT')}</span>
                </li>
            </ul>
            }
        </div>
        )
    }
}

export default withRouter(ProfileMenuComponent)