import React from "react";
import BackArrow from '../../assets/images/back-arrow.svg';
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
class HeaderPageMobile extends React.Component{
    constructor(){
     super()
     this.state = {currentText:"Back",link :"/home",redirect:false}
    }

    componentDidUpdate(prevProps) {
        const { headerMobileBack } = this.props;
        if (headerMobileBack && prevProps.headerMobileBack !== headerMobileBack) {

            if(headerMobileBack.name) {
                this.setState({currentText:headerMobileBack.name})
            }
            if(headerMobileBack.link ) {
                this.setState({link:headerMobileBack.link})
            }


        }
    }   

    backButton(){
        this.props.history.push(this.state.link)

    }
    render(){

 

        return(
            <div className="header header-dashboard header-inner-page">
                <div className="header-page-content" onClick={() => this.backButton()}>
                    <img src={BackArrow} alt="bakck arrow" loading="lazy" />
                    <h3>{this.state.currentText}</h3>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return { headerMobileBack: state.headerMobileBack }
}

export default withRouter(connect(mapStateToProps)(HeaderPageMobile));