import React from "react";
import './NoInternetStyle.scss';
import i18next from 'i18next';

class NoInternetComponent extends React.Component {
    render() {
        return (
            <div className="popup-layout">
                <div className="popup-overlay">
                    <div className="popup-box popup-internet-width">
                        <div className="popup-content no-internet-popup-box custom-scroll">
                            <div className="no-internet-popup">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="101.527" viewBox="0 0 114.001 101.527">
                                        <g id="Group_5983" data-name="Group 5983" transform="translate(-817.5 -435.5)">
                                            <g id="Icon_feather-wifi-off" data-name="Icon feather-wifi-off" transform="translate(817.5 435.5)">
                                                <path id="Path_108" data-name="Path 108" d="M90.637,95.472a4.819,4.819,0,0,1-3.419-1.416l-85.8-85.8A4.835,4.835,0,0,1,8.253,1.416l85.8,85.8a4.835,4.835,0,0,1-3.419,8.253Z" transform="translate(6.056 6.056)" fill="#f9b200" />
                                                <path id="Path_109" data-name="Path 109" d="M39.436,31.964a4.815,4.815,0,0,1-3.126-1.148A48.1,48.1,0,0,0,26.295,24.27a4.835,4.835,0,0,1,4.241-8.69,57.769,57.769,0,0,1,12.031,7.862,4.835,4.835,0,0,1-3.13,8.521Z" transform="translate(52.42 33.546)" fill="#f9b200" />
                                                <path id="Path_110" data-name="Path 110" d="M10.837,34.965a4.835,4.835,0,0,1-3.1-8.544A57.791,57.791,0,0,1,35.013,13.81a4.835,4.835,0,1,1,1.633,9.53,48.111,48.111,0,0,0-22.71,10.5A4.815,4.815,0,0,1,10.837,34.965Z" transform="translate(13.338 30.545)" fill="#f9b200" />
                                                <path id="Path_111" data-name="Path 111" d="M76.785,35.016a4.815,4.815,0,0,1-3.2-1.209,72.89,72.89,0,0,0-53.8-17.9,4.835,4.835,0,1,1-.777-9.638,82.189,82.189,0,0,1,60.974,20.29,4.835,4.835,0,0,1-3.2,8.46Z" transform="translate(32.379 13.33)" fill="#f9b200" />
                                                <path id="Path_112" data-name="Path 112" d="M5.466,31.274a4.835,4.835,0,0,1-3.2-8.457,81.516,81.516,0,0,1,24.151-14.8,4.835,4.835,0,1,1,3.547,9A71.874,71.874,0,0,0,8.667,30.062,4.817,4.817,0,0,1,5.466,31.274Z" transform="translate(1.401 17.072)" fill="#f9b200" />
                                                <path id="Path_113" data-name="Path 113" d="M49.727,36.027a4.811,4.811,0,0,1-2.8-.894,24.2,24.2,0,0,0-28,0,4.835,4.835,0,1,1-5.6-7.882,33.873,33.873,0,0,1,39.2,0,4.835,4.835,0,0,1-2.8,8.776Z" transform="translate(25.11 46.694)" fill="#f9b200" />
                                                <path id="Path_114" data-name="Path 114" fill="#f9b200" />
                                            </g>
                                            <circle id="Ellipse_31" data-name="Ellipse 31" cx="4.943" cy="4.943" r="4.943" transform="translate(870.557 527.141)" fill="#f9b200" />
                                        </g>
                                    </svg>

                                </figure>
                                <h4>{i18next.t('NO_INTERNET.TITLE')}</h4>
                                <p>{i18next.t('NO_INTERNET.DESCRIPTION')}</p>
                                <button type='button' onClick={() => this.props.checkInternet()}>{i18next.t('NO_INTERNET.TRY_AGAIN')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default NoInternetComponent;