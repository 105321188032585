import  { RESET_TIMER_FAILURE, RESET_TIMER_SUCCESS ,FAULT_CODE_FAILURE, FAULT_CODE_SUCCESS } from './FaultCodeType';

const initialState = { isFetching: false, data: [] }

export const faultCodeData = (  state = initialState, { type ,payload } = {}) => {
    switch (type) {
        case FAULT_CODE_FAILURE:
        case FAULT_CODE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const resetTimer = (  state = initialState, { type ,payload } = {}) => {
    switch (type) {
        case RESET_TIMER_SUCCESS:
        case RESET_TIMER_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}