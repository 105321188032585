export const  DETAIL_DASHBOARD_COUNT_FAILURE = 'DETAIL_DASHBOARD_COUNT_FAILURE';
export const  DETAIL_DASHBOARD_COUNT_SUCCESS =  'DETAIL_DASHBOARD_COUNT_SUCCESS';

export const  DETAIL_DASHBOARD_LOG_COUNT_FAILURE = 'DETAIL_DASHBOARD_LOG_COUNT_FAILURE';
export const  DETAIL_DASHBOARD_LOG_COUNT_SUCCESS =  'DETAIL_DASHBOARD_LOG_COUNT_SUCCESS';


export const  DETAIL_DASHBOARD_MACHINE_COUNT_FAILURE = 'DETAIL_DASHBOARD_MACHINE_COUNT_FAILURE';
export const  DETAIL_DASHBOARD_MACHINE_COUNT_SUCCESS =  'DETAIL_DASHBOARD_MACHINE_COUNT_SUCCESS';


export const  DETAIL_DASHBOARD_DEALER_COUNT_FAILURE = 'DETAIL_DASHBOARD_DEALER_COUNT_FAILURE';
export const  DETAIL_DASHBOARD_DEALER_COUNT_SUCCESS =  'DETAIL_DASHBOARD_DEALER_COUNT_SUCCESS';



