import Authorization from '../utility/Authorization';
import { Route, Redirect } from "react-router-dom";
const PublicRouter = ({ component: Component, dispatch, exact, path, ...rest }) => {

    return (<Route {...rest} render={(props) => {
        if(!exact && path === '/'){
            return <Redirect to='/'></Redirect> 
        }
        if ((path === '/' || path ===   '/login') && Authorization.isLoggedIn() && Authorization.isVariantId() && Authorization.isModelId()) {
            return <Redirect to='/home'></Redirect>
        } else if ((path === "/" || path ===  '/login') && Authorization.isLoggedIn()) {
            return <Redirect to='/model-list'></Redirect>
        }
        return <Component path={path} exact={exact} ></Component>
    }} />
    )
}

export default PublicRouter
