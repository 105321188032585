import './FooterStyle.scss';
import i18next from "i18next";
import { withRouter } from 'react-router-dom';
import {Routerexclude} from '../../utility/Routerexclude';
import React from 'react';

class FooterComponent extends React.Component {
    constructor(props){
        super(props);
        this.unlisten = '';
        this.pathname = this.props.location.pathname;
        this.date = new Date();
    }
    
    componentDidMount(){
        this.unlisten =  this.props.history.listen((location, action) => {
          this.pathname = location.pathname;
          })
      }


    componentWillUnmount(){
        this.unlisten();
     }


    render(){
        if(Routerexclude.includes(this.pathname)){
        return (
        <div className="footer">
            <p>&copy;{this.date.getFullYear()} {i18next.t('BRAND.FULL_NAME')}.</p>
        </div>);
        }
        else{
            return null;
        }
    }
}

export default withRouter(FooterComponent)