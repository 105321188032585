
import  {DYNAMIC_MODEL_LIST_FAILURE , DYNAMIC_MODEL_LIST_SUCCESS,DASHBOARD_DIAGONSTICS_LIST_FAILURE, DASHBOARD_DIAGONSTICS_LIST_SUCCESS, DASHBOARD_SETUP_LIST_FAILURE, DASHBOARD_SETUP_LIST_SUCCESS, DASHBOARD_DATALOGGER_LIST_FAILURE, DASHBOARD_DATALOGGER_LIST_SUCCESS, DASHBOARD_CANLOGGER_LIST_SUCCESS, DASHBOARD_CANLOGGER_LIST_FAILURE, DIAGONSTICS_LIST_FAILURE, DIAGONSTICS_LIST_SUCCESS, SELECTED_DIAGONSTICS_FAILURE, SELECTED_DIAGONSTICS_SUCCESS} from './DynamicModuleType';
const initialState = { data: [] , isFetching: false }

export const diagonsticsListing = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DIAGONSTICS_LIST_FAILURE:
        case DIAGONSTICS_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const selectedDiagonstics = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case SELECTED_DIAGONSTICS_FAILURE:
        case SELECTED_DIAGONSTICS_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const dashboardDiagonsticsList = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DASHBOARD_DIAGONSTICS_LIST_SUCCESS:
        case DASHBOARD_DIAGONSTICS_LIST_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}


export const dashboardSetupList = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DASHBOARD_SETUP_LIST_FAILURE:
        case DASHBOARD_SETUP_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const dashboardDataLoggerList = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DASHBOARD_DATALOGGER_LIST_FAILURE:
        case DASHBOARD_DATALOGGER_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const dashboardCanLoggerList = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DASHBOARD_CANLOGGER_LIST_FAILURE:
        case DASHBOARD_CANLOGGER_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const dynamicModelList = (  state =  initialState , { type ,payload } = {}) => {
    switch (type) {
        case DYNAMIC_MODEL_LIST_FAILURE:
        case DYNAMIC_MODEL_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

            
