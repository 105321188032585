import { toast } from 'react-toastify';

class CustomToast{
    success(val=""){
        toast.dismiss();
        toast.success(val,{className: 'custom-toaster custom-success-message'});
    }
    error(val=""){
        toast.dismiss();
        toast.error(val,{className: 'custom-toaster custom-err-message'});
    }
    warning(val=""){
        toast.dismiss();
        toast.warn(val,{className: 'custom-toaster custom-warning-message'});
    }
}

export default new CustomToast()