
import { DELETE_MACHINE_FAILURE } from '../user-related/UserType';
import  { MACHINE_MODEL_UPDATE_FAILURE , MACHINE_MODEL_UPDATE_SUCCESS ,MACHINE_MODEL_LIST_FAILURE , MACHINE_MODEL_LIST_SUCCESS, MACHINE_MODEL_NEW_LIST_FAILURE, MACHINE_MODEL_NEW_LIST_SUCCESS, MACHINE_MDS_CODE_FAILURE, MACHINE_MDS_CODE_SUCCESS, MACHINE_MDS_CODE_LIST_FAILURE, MACHINE_MDS_CODE_LIST_SUCCESS, EDIT_MACHINE_MDS_CODE_FAILURE, EDIT_MACHINE_MDS_CODE_SUCCESS, DELETE_MACHINE_MDS_CODE_FAILURE, DELETE_MACHINE_MDS_CODE_SUCCESS, VERIFY_MDS_CODE_USER_FAILURE, VERIFY_MDS_CODE_USER_SUCCESS, MACHINE_MDS_CODE_LIST_ALL_FAILURE, MACHINE_MDS_CODE_LIST_ALL_SUCCESS } from './MachineModelType';

export const MachineModelReducer = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MODEL_LIST_FAILURE:
        case MACHINE_MODEL_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const fetchMDSCodeList = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MDS_CODE_LIST_FAILURE:
        case MACHINE_MDS_CODE_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}
export const fetchMDSCodeListAll = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MDS_CODE_LIST_ALL_FAILURE:
        case MACHINE_MDS_CODE_LIST_ALL_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const createMDSCode = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MDS_CODE_FAILURE:
        case MACHINE_MDS_CODE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const deleteMDSCode = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DELETE_MACHINE_MDS_CODE_FAILURE:
        case DELETE_MACHINE_MDS_CODE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const verifyUserMDSCode = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case VERIFY_MDS_CODE_USER_FAILURE:
        case VERIFY_MDS_CODE_USER_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const editMDSCode = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case EDIT_MACHINE_MDS_CODE_FAILURE:
        case EDIT_MACHINE_MDS_CODE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const MachineModelNewReducer = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MODEL_NEW_LIST_FAILURE:
        case MACHINE_MODEL_NEW_LIST_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const updateMachineModel = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case MACHINE_MODEL_UPDATE_FAILURE:
        case MACHINE_MODEL_UPDATE_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}