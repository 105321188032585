const MOBILE_BACK_DATA_FAILURE = "MOBILE_BACK_DATA_FAILURE";
const MOBILE_BACK_DATA_SUCCESS = "MOBILE_BACK_DATA_SUCCESS";

const initialState = { isFetching: false, data: [] }
export const headerMobileBack  = (  state = initialState , { type ,payload } = {}) => {
    switch (type) {
        case MOBILE_BACK_DATA_FAILURE:
        case MOBILE_BACK_DATA_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}