import {DETAIL_DASHBOARD_LOG_COUNT_FAILURE,DETAIL_DASHBOARD_LOG_COUNT_SUCCESS,DETAIL_DASHBOARD_MACHINE_COUNT_FAILURE,DETAIL_DASHBOARD_MACHINE_COUNT_SUCCESS,DETAIL_DASHBOARD_DEALER_COUNT_FAILURE,DETAIL_DASHBOARD_DEALER_COUNT_SUCCESS, DETAIL_DASHBOARD_COUNT_FAILURE,DETAIL_DASHBOARD_COUNT_SUCCESS}  from './DetailDashboardType';



export const detailDashboardCount = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DETAIL_DASHBOARD_COUNT_FAILURE:
        case DETAIL_DASHBOARD_COUNT_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const detailDashboardLogCount = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DETAIL_DASHBOARD_LOG_COUNT_FAILURE:
        case DETAIL_DASHBOARD_LOG_COUNT_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const detailDashboardDealerCount = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DETAIL_DASHBOARD_DEALER_COUNT_FAILURE:
        case DETAIL_DASHBOARD_DEALER_COUNT_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}


export const detailDashboardMachineCount = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DETAIL_DASHBOARD_MACHINE_COUNT_SUCCESS:
        case DETAIL_DASHBOARD_MACHINE_COUNT_FAILURE:
            return ({}, payload);

        default:
            return state;
    }
}