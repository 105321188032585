import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../../src/locales/en.json'


const resources = {
  en: {
    translation: en
  }

}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: '.', // to use the nested keys in the json
    nsSeparator: false,
    debug: false,  // to enable/disable the console log
    parseMissingKeyHandler: () => {
      return "KEY-NOT-FOUND";
    }, // parseMissingKeyHandler is to handle the missing keys
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;