import React from 'react'
import './HeaderStyle.scss';
import HeaderDashboard from './HeaderDashboard';
import HeaderLogin from './HeaderLogin'
import { withRouter } from 'react-router-dom';
import Authorization from '../../utility/Authorization';
import { Routerexclude } from '../../utility/Routerexclude';
import HeaderPageMobile from './HeaderPageMobile';
import  CommonService  from '../../utility/CommonService';

class HeaderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.unlisten = '';
    this.state = {  isLoginDone: false, isMobile: false, prevLocation:''}
    this.pathname = this.props.location.pathname;
    this.reportWindowSize = this.reportWindowSize.bind(this);
    
  }


/*Using the checkUser() we are checking the user is logedin  or not, To switch the neccessary headers*/
checkUser(){
  if(Authorization.isLoggedIn() && Authorization.isVariantId() && Authorization.isModelId()){
    this.setState({isLoginDone : true});
  } else {
    this.setState({isLoginDone : false});
  }
}



reportWindowSize() {
  const result =  CommonService.isMobile();
  if(result || !result){
    this.setState({isMobile : result }) 
  }
}


/* in componentDidMount we are calling checkUser() to get the  logedin staus */
componentDidMount(){
  const setEnv = {
    "type" :  process.env.REACT_APP_ENV_TYPE,
    "socket_url" : process.env.REACT_APP_SOCKET_BASE_URL,
    "api_url" :  process.env.REACT_APP_BACKEND_BASE_URL
  }
  Authorization.setEnviroment(setEnv);
  this.reportWindowSize();
  this.checkUser();
  this.unlisten = this.props.history.listen((location, action) => {
    this.pathname = location.pathname;
    this.setState({prevLocation :location })
    this.checkUser();
    })
    window.addEventListener('resize', this.reportWindowSize); 
}



componentWillUnmount(){
  window.removeEventListener('resize', this.reportWindowSize);
  this.unlisten();
}


/*Based on the Authorization we are swithching between  HeaderLogin and  HeaderDashboard*/
  render() {

    
    if (!this.state.isLoginDone || Routerexclude.includes(this.pathname) || this.pathname.match(/reset-password/g)) {
      return (<HeaderLogin />);
    }

    if (this.state.isLoginDone || !Routerexclude.includes(this.pathname)) {
      if(this.state.isMobile){
        if(this.pathname !==  '/home' ){
          return(<HeaderPageMobile />)
        }
        else{
          return(<HeaderDashboard />)
        }

      } else {
        return (<HeaderDashboard />);

      }
    }



  }

}

export default withRouter(HeaderComponent)